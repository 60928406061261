<template>
  <!-- 新建热门好课页面 -->
  <div class="positionT0L0">
    <a-form-model
      ref="ruleForm"
      :model="form"
      labelAlign="left"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 8 }"
    >
      <a-form-model-item
        label="课程推广图"
        :class="{ 'has-error': !form.promotionUrl && isShowPromotionUrl }"
        required
      >
        <CropperUpload
          :imageUrl="form.promotionUrl"
          :options="promotionOptions"
          @avatarfn="promotionUrlFn"
          avatarTextTop="应用于首页精品馆"
          avatarTextBottom="建议尺寸686px*386px,JPG.PNG.JPEG格式 图片小于1M"
        />
        <div
          class="ant-form-explain"
          v-if="!form.promotionUrl && isShowPromotionUrl"
        >
          请上传课程推广图
        </div>
      </a-form-model-item>
      <a-form-model-item
        label="选择商品"
        :class="{ 'has-error': !isShowGoods && isShowProduct }"
        required
      >
        <div class="product-box" v-if="isShowGoods">
          <a-icon
            type="close-circle"
            :style="{ fontSize: '20px', color: '#9FA5AF' }"
            class="product-close"
            @click="closeHandle"
          />
          <div class="product-wrap flexbox justify middle">
            <div class="left-wrap flex1 flexbox left">
              <div class="product-img">
                <img class="p-img" :src="goodsDetail.coverUrl" alt="图片" />
              </div>
              <div class="product-info flex1">
                <div style="height: 56px">
                  <div class="p-title more-t">{{ goodsDetail.name }}</div>
                  <div v-show="goodsDetail.pricingType == 0" class="p-price">
                    免费
                  </div>
                  <div v-show="goodsDetail.pricingType == 1" class="p-price">
                    ￥{{ goodsDetail.price.toFixed(2) }}
                  </div>
                  <div v-show="goodsDetail.pricingType == 2" class="p-price">
                    不支持单独购买
                  </div>
                </div>
                <div class="p-type">
                  商品类型：{{
                    goodsDetail.courseType == 1 ? "伴读课" : "网课"
                  }}
                </div>
              </div>
            </div>
            <div class="right-wrap">
              <a-button type="primary" @click="() => (modalVisible = true)"
                >更换商品</a-button
              >
            </div>
          </div>
        </div>
        <a-button v-else type="primary" @click="() => (modalVisible = true)">
          添加商品
        </a-button>
        <div class="ant-form-explain" v-if="!isShowGoods && isShowProduct">
          请添加商品
        </div>
      </a-form-model-item>
    </a-form-model>

    <footer-tool-bar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)"
        >返回</a-button
      >
      <a-button
        type="primary"
        @click="keepClick"
        :loading="keepLoading"
        :disabled="keepLoading"
        >保存</a-button
      >
    </footer-tool-bar>
    <RadioModal
      v-if="modalVisible"
      :modalVisible="modalVisible"
      :tabType="businessType"
      :type="1"
      :selectedRowKeys="selectedRowKeys"
      @cancel="() => (modalVisible = false)"
      @ok="ok"
    />
  </div>
</template>

<script>
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import RadioModal from "@/components/courseMember/RadioModal";
import {
  GetCourseBoutiqueResultApi,
  SaveBoutiqueApi,
  UpdBoutiqueApi,
} from "@/request/api/indexManage";
import CropperUpload from "@/components/file/CropperUpload.vue";
import { message } from "ant-design-vue";
export default {
  mixins: [baseMixin],
  components: { CropperUpload, FooterToolBar, RadioModal },
  created() {
    this.goodsDetail = null;
    let id = this.$route.params.id;
    if (id !== "0") {
      GetCourseBoutiqueResultApi({ id: id }).then(({ code, data }) => {
        if (code == 200) {
          this.isShowGoods = true;
          this.form.promotionUrl = data.promotionUrl;
          this.form.promotionCosKey = data.promotionCosKey;
          this.goodsDetail = data;
        }
      });
    }
  },
  data() {
    return {
      businessType: 1, // 1-课程   2-会员
      selectedRowKeys: [],
      modalVisible: false, // 弹框是否显示
      keepLoading: false, // 保存的按钮是否加载
      isShowGoods: false, // 商品是否显示
      isShowProduct: false, // 商品是否显示辅助
      isShowPromotionUrl: false, // 是否显示推广课程图的错误提示
      form: {
        promotionUrl: "",
        promotionCosKey: "",
      },
      goodsDetail: {
        coverUrl: "",
        name: "",
        price: "",
        courseNo: "", // 返回的 courseNo
      },
      promotionOptions: {
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 686, //默认生成截图框宽度
        autoCropHeight: 386, //默认生成截图框高度
        fixedBox: true, //是否固定截图框大小 不允许改变
        previewsCircle: false, //预览图是否是原圆形
        title: "修改图片",
      },
    };
  },
  methods: {
    // 课程推广图片路径
    promotionUrlFn(data) {
      this.form.promotionUrl = data.url;
      this.form.promotionCosKey = data.cosKey;
    },
    // 商品关闭按钮
    closeHandle() {
      this.goodsDetail = null;
      this.isShowGoods = false;
    },
    // 弹框的确认按钮
    ok(SelectedRowKeys, obj, businessType) {
      this.goodsDetail = obj;
      this.goodsDetail.courseNo = obj.key;
      this.modalVisible = false;
      this.isShowGoods = true;
    },
    // 保存的按钮
    keepClick() {
      this.keepLoading = true;
      setTimeout(() => {
        this.keepLoading = false;
      }, 3000);
      let id = this.$route.params.id;
      if (id == "0") {
        id = "";
      }
      if (!this.isShowGoods) {
        this.isShowProduct = true;
      }
      if (!this.form.promotionUrl) {
        this.isShowPromotionUrl = true;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid && this.goodsDetail) {
          if (this.$route.params.id != 0) {
            UpdBoutiqueApi({
              id: id,
              courseNo: this.goodsDetail.courseNo,
              promotionUrl: this.form.promotionUrl,
              promotionCosKey: this.form.promotionCosKey,
            }).then(({ code }) => {
              if (code == 200) {
                message.success("修改成功", 1);
                location.href = "/indexManage/indexConfig";
              }
            });
          } else {
            SaveBoutiqueApi({
              id: id,
              courseNo: this.goodsDetail.courseNo,
              promotionUrl: this.form.promotionUrl,
              promotionCosKey: this.form.promotionCosKey,
            }).then(({ code }) => {
              if (code == 200) {
                message.success("新增成功", 1);
                location.href = "/indexManage/indexConfig";
              }
            });
          }
        } else {
          message.error("还有必填项未完成");
        }
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
.product-box {
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  .product-close {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
  .product-wrap {
    height: 86px;
    .left-wrap {
      .product-img {
        margin-right: 10px;
        height: 86px;
        .p-img {
          height: 86px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      .product-info {
        flex-direction: column;
        height: 86px;
        .p-title {
          margin-top: 6px;
          font-size: 14px;
          color: #000;
        }
        .p-price {
          color: red;
        }
        .p-type {
          color: #9fa5af;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .right-wrap {
      margin-left: 24px;
      margin-right: 24px;
    }
  }
}
/deep/.product .ant-form-item-control {
  line-height: normal;
}
/deep/.edu-type .ant-form-item-children {
  display: flex;
}
/deep/.edu-type .ant-select:nth-child(2) {
  margin-left: 20px;
}
/deep/.edu-quantity .ant-input-number {
  width: 100%;
}
/deep/.date-picker .ant-form-item-children {
  display: flex;
  align-items: center;
}
/deep/.ant-form-item-control {
  line-height: 22px;
}
</style>